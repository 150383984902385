/* document.addEventListener('DOMContentLoaded', () => {
    fetch('/grandangolo.json')
    .then((content) => content.json())
    .then((data) => {
        [].forEach.call(document.getElementsByClassName('grandangolo-title'), element => {
            element.textContent = data.title;
        });

        [].forEach.call(document.getElementsByClassName('grandangolo-image'), element => {
            element.src = data.image;
        });

        [].forEach.call(document.getElementsByClassName('grandangolo-url'), element => {
            element.onclick = null;
            element.addEventListener('click', () => onSquareClick(data.url));
        });
    })
}); */